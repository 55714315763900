import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrderSegment } from '../models';

export const orderSegmentActions = createActionGroup({
    source: 'Order Segment',
    events: {
        'Get Order By Token': props<{ id: string; token: string }>(),
        'Get Order By Token Success': props<{ payload: OrderSegment }>(),
        'Get Order By Token Error': props<{ response: HttpErrorResponse }>(),

        Clear: emptyProps(),
    },
});
