import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ORDER_SEGMENT_FEATURE_KEY } from './keys';
import { orderSegmentReducer } from './reducers/order-segment.reducer';
import { OrderSegmentEffects } from './effects/order-segment.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(ORDER_SEGMENT_FEATURE_KEY, orderSegmentReducer), EffectsModule.forFeature([OrderSegmentEffects])],
})
export class OrderSegmentStoreModule {}
