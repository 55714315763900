import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { OrderSegmentFacade } from '../facades/order-segment.facade';

export function orderSegmentCleanup<T>(): CanDeactivateFn<T> {
    return () => {
        const orderSegmentFacade = inject(OrderSegmentFacade);
        orderSegmentFacade.clear();

        return true;
    };
}
