import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { of, catchError, finalize, map, mergeMap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoaderFacade } from '@app/store/loader';
import { HttpService } from '../services/http.service';
import { orderSegmentActions } from '../actions/order-segment.actions';

@Injectable()
export class OrderSegmentEffects {
    private readonly actions = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly loaderFacade = inject(LoaderFacade);

    getOrderSegmentByToken$ = createEffect(() => {
        return this.actions.pipe(
            ofType(orderSegmentActions.getOrderByToken),
            mergeMap((action) => {
                this.loaderFacade.add('order-segment-get');
                return this.httpService.getOrderSegmentByToken(action.token).pipe(
                    map((payload) => orderSegmentActions.getOrderByTokenSuccess({ payload })),
                    catchError((response: HttpErrorResponse) => of(orderSegmentActions.getOrderByTokenError({ response }))),
                    finalize(() => this.loaderFacade.remove('order-segment-get')),
                );
            }),
        );
    });
}
