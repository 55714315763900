import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ApiResponse } from '@app/shared/models';
import { map, Observable } from 'rxjs';
import { OrderSegment, OrderSegmentDto } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getOrderSegmentByToken(token: string): Observable<OrderSegment> {
        return this.httpClient
            .addServer()
            .skipErrorHandler()
            .get<ApiResponse<OrderSegmentDto>>(`api/order-analytic/${token}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .pipe(map((response) => new OrderSegment(response.data)));
    }
}
