import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrderSegmentState } from '../reducers/order-segment.reducer';
import { ORDER_SEGMENT_FEATURE_KEY } from '../keys';

const selectOrderState = createFeatureSelector<OrderSegmentState>(ORDER_SEGMENT_FEATURE_KEY);

export const selectOrder = createSelector(selectOrderState, (state: OrderSegmentState) => state.order);
export const selectLoaded = createSelector(selectOrderState, (state: OrderSegmentState) => state.loaded);
export const selectLoading = createSelector(selectOrderState, (state: OrderSegmentState) => state.loading);
export const selectLoadedErrorCode = createSelector(selectOrderState, (state: OrderSegmentState) => state.loadedErrorCode);
