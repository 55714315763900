import { createReducer, on } from '@ngrx/store';
import { orderSegmentActions } from '../actions/order-segment.actions';
import { OrderSegment } from '../models';

export interface OrderSegmentState {
    order: OrderSegment | null;
    loaded: boolean | null;
    loadedErrorCode: number | null;
    loading: boolean;
}

export const initialState: OrderSegmentState = {
    order: null,
    loaded: null,
    loadedErrorCode: null,
    loading: false,
};

export const orderSegmentReducer = createReducer(
    initialState,
    on(
        orderSegmentActions.getOrderByToken,
        (state): OrderSegmentState => ({
            ...state,
            loading: true,
            order: null,
            loadedErrorCode: null,
        }),
    ),
    on(
        orderSegmentActions.getOrderByTokenSuccess,
        (state, action): OrderSegmentState => ({
            ...state,
            loading: false,
            loaded: true,
            order: action.payload,
        }),
    ),
    on(
        orderSegmentActions.getOrderByTokenError,
        (state): OrderSegmentState => ({
            ...state,
            loading: false,
            loaded: false,
        }),
    ),
    on(
        orderSegmentActions.clear,
        (): OrderSegmentState => ({
            ...initialState,
        }),
    ),
);
