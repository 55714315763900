import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';
import { selectOrder, selectLoaded, selectLoadedErrorCode, selectLoading } from '../selectors/order-segment.selector';
import { orderSegmentActions } from '../actions/order-segment.actions';
import { OrderSegment } from '../models';

@Injectable({
    providedIn: 'root',
})
export class OrderSegmentFacade {
    private store = inject(Store);

    readonly order$ = this.store.select(selectOrder).pipe(filter((order) => order !== null));

    readonly loaded$ = this.store.select(selectLoaded).pipe(filter((loaded) => loaded !== null));
    readonly loadedError$ = this.store.select(selectLoadedErrorCode).pipe(filter((error) => error !== null));
    readonly loading$ = this.store.select(selectLoading);

    get order(): OrderSegment | null {
        let response: OrderSegment | null = null;

        this.store
            .select(selectOrder)
            .pipe(
                tap((value) => (response = value)),
                take(1),
            )
            .subscribe();

        return response;
    }

    getOrderByToken(id: string, token: string) {
        this.store.dispatch(orderSegmentActions.getOrderByToken({ id, token }));
    }

    clear(): void {
        this.store.dispatch(orderSegmentActions.clear());
    }
}
