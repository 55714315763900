import { OptionType } from '@app/store/booking-options/types';
import { OrderSegmentDto, OrderSegmentItemDto } from './order-segment.dto';
import { CURRENCY } from '@env/currency.type';

export class OrderSegment {
    id: string;
    currency: CURRENCY;
    items: OrderSegmentItem[];
    number: string;
    price: number;
    email: string;

    constructor(data: OrderSegmentDto) {
        this.id = data.id;
        switch (data.currency) {
            case 'pln':
                this.currency = 'PLN';
                break;
            case 'eur':
                this.currency = 'EUR';
                break;
            case 'ron':
                this.currency = 'RON';
                break;
        }
        this.items = data.items.map((item) => new OrderSegmentItem(item));
        this.number = data.number;
        this.price = data.price;
        this.email = data.email;
    }
}

export class OrderSegmentItem {
    id: string;
    name: OptionType;
    price: number;
    currency: CURRENCY;

    constructor(data: OrderSegmentItemDto) {
        this.id = data.id;
        this.name = data.name;
        this.price = data.price;
        switch (data.currency) {
            case 'pln':
                this.currency = 'PLN';
                break;
            case 'eur':
                this.currency = 'EUR';
                break;
            case 'ron':
                this.currency = 'RON';
                break;
        }
    }
}
